<template>
  <div class="bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="prevPage"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Detail Transfer Stok
          </h1>
        </b-col>
        <!-- <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            class="px-3"
            :disabled="disabled"
            @click="nextSubmit"
          >
            Simpan
          </b-button>
        </b-col> -->
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <!-- <keep-alive> -->
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <div>
            <b-form-group
              class="custom__form--input"
            >
              <label for="v-date">Tanggal Masuk <span class="text-danger">*</span></label>
              <b-form-input
                id="v-date"
                v-model="formPayload.date"
                type="date"
                class="custom__input"
                style="padding-top: 1rem;"
                placeholder="dd/mm/yyy"
                disabled
              />
            </b-form-group>

            <b-form-group
              class="custom__form--input"
            >
              <label for="">Pilih Lokasi SKU Asal <span class="text-danger">*</span></label>
              <v-select
                v-model="formPayload.warehouse_origin_uuid"
                label="name"
                :reduce="option => option.uuid"
                :options="options"
                placeholder="Pilih Lokasi SKU Asal"
                disabled
              />
            </b-form-group>

            <b-form-group
              class="custom__form--input"
            >
              <label for="">Pilih Lokasi SKU Tujuan <span class="text-danger">*</span></label>
              <v-select
                v-model="formPayload.warehouse_destination_uuid"
                label="name"
                :reduce="option => option.uuid"
                :options="options"
                placeholder="Pilih Lokasi SKU Tujuan"
                disabled
              />
            </b-form-group>

            <b-form-group
              class="custom__form--input"
            >
              <label for="v-delivery">No. Surat Jalan</label>
              <b-form-input
                id="v-delivery"
                v-model="formPayload.delivery_number"
                type="text"
                class="custom__input"
                style="padding-top: 1rem;"
                placeholder="No. Surat Jalan"
              />
            </b-form-group>

            <b-form-group
              class="custom__form--input"
            >
              <label for="v-memo">Memo</label>
              <b-form-textarea
                id="v-memo"
                v-model="formPayload.memo"
                rows="5"
                placeholder="Memo"
                class="custom__textarea"
                disabled
              />
            </b-form-group>

            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in formPayload.items"
                :id="index"
                :key="index"
                ref="row"
              >

                <!-- Item Name -->
                <b-col md="5">
                  <b-form-group
                    label="Nama Produk"
                    label-for="item-name"
                  >
                    <v-select
                      v-model="formPayload.items[index].product"
                      label="name"
                      :reduce="option => option.uuid"
                      :options="optionsProduct"
                      placeholder="Pilih Produk"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="3">
                  <b-form-group
                    label="Qty"
                    label-for="quantity"
                  >
                    <input
                      :id="`qty-${index}`"
                      :value="formPayload.items[index].qty_formatted"
                      type="text"
                      class="custom__input pl-1"
                      disabled
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

            </b-form>
          </div>
        </b-col>
        <!-- </keep-alive> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BForm, BButton, BRow, BCol, BContainer, BProgress, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat } from '@/auth/utils'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BFormInput,
    BProgress,
    BForm,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    return {
      checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat,
    }
  },
  data() {
    return {
      moment,
      options: [],
      messages: Object,
      isLoading: false,
      formPayload: {
        date: '',
        warehouse_origin_uuid: '',
        warehouse_destination_uuid: '',
        memo: '',
        items: [
          {
            product_uuid: '',
            qty: 0,
          },
        ],
      },
      step: this.$store.state.formWizard.step,
      progressValue: 50,
      disabled: false,
      optionsProduct: [],
      result: {},
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.formWizard.step': function (value) {
      this.step = value
      this.stepForm()
    },
    formPayload: {
      handler(value) {
        this.disabledStep1(value)
      },
      deep: true,
    },
  },
  mounted() {
    this.getWarehouses()
    this.getItems()
    this.getData()
    // this.disabledStep1(this.formPayload)
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    async getData() {
      try {
        const result = await this.$store.dispatch('stockWarehouse/getTransfer', {
          uuid: this.$route.params.id,
          params: '',
        });

        this.formPayload = {
          date: moment(result.data.data.date).format('YYYY-MM-DD'),
          warehouse_origin_uuid: result.data.data.warehouse_origin || result.data.data.supplier_origin,
          warehouse_destination_uuid: result.data.data.warehouse_destination,
          memo: result.data.data.memo,
          items: result.data.data.items,
          delivery_number: result.data.data.delivery_number,
        };

        this.result = result.data.data;
      } catch (err) {
        console.log(err);
      }
    },

    getQty(index) {
      const qty = document.getElementById(`qty-${index}`);
      qty.value = this.formatRupiah(qty.value, '');
      this.formPayload.items[index].qty = qty.value;
    },

    async getItemsDetail(id) {
      try {
        const result = await this.$store.dispatch('parentProduct/getData', {
          uuid: id || '',
          params: '',
        });

        this.item = result.data.data.name;
        setTimeout(() => {
          this.optionsProduct.unshift({ label: result.data.data.uuid, title: result.data.data.name });
          const option = this.getUniqueListBy(this.optionsProduct, 'label');
          this.optionsProduct = option;
        }, 500);
      } catch (err) {
        console.log(err);
      }
    },

    onSearchItem(search, loading) {
      if (search.length) {
        loading(true);
        this.searchItem(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsProduct = [];
          this.getData();
          loading(false);
        }, 500);
      }
    },

    searchItem: _.debounce(async (loading, search, vm) => {
      try {
        const response = await vm.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: {
            search: escape(search),
          },
        });

        vm.optionsProduct = [];
        response.data.data.data.forEach(item => {
          vm.optionsProduct.push({
            label: item.uuid,
            title: item.name,
          });
        });

        loading(false);
      } catch (err) {
        console.log(err);
      }
    }, 300),

    async getUomDetail(id) {
      try {
        const result = await this.$store.dispatch('uom/getData', {
          uuid: id,
          params: '',
        });

        setTimeout(() => {
          this.optionUom.push({
            label: result.data.data.uuid,
            name: result.data.data.name,
          });
          const option = this.getUniqueListBy(this.optionUom, 'label');
          this.optionUom = option;
        }, 500);
      } catch (err) {
        console.log(err);
      }
    },

    async getItems() {
      try {
        const result = await this.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: {
            per_page: 50,
          },
        });
        
        this.optionsProduct = result.data.data.data.map(element => ({
          title: element.alias_name,
          label: element.uuid,
        }));

      } catch (err) {
        console.log(err);
      }
    },

    repeateAgain() {
      this.formPayload.items.push({
        product_uuid: '',
        qty: 0,
      });
    },

    removeItem(index) {
      this.formPayload.items.splice(index, 1);
    },

    disabledStep1(value) {
      if (value) {
        const form = {
          date: value.date,
          warehouse_origin_uuid: value.warehouse_origin_uuid,
          warehouse_destination_uuid: value.warehouse_destination_uuid,
          memo: value.memo,
        };

        this.disabled = Object.values(form).some(e => e === '');
      }
    },

    nextSubmit() {
      if (this.step === 1) {
        this.$store.commit('formWizard/setStep', 2);
      }
      if (this.step === 2) {
        this.createItem();
      }
    },

    prevPage() {
      switch (this.step) {
        case 1:
          this.$router.go(-1);
          break;
        case 2:
          this.$store.commit('formWizard/setStep', 1);
          break;
        default:
          break;
      }
    },

    stepForm() {
      switch (this.step) {
        case 1:
          this.progressValue = 50;
          break;
        case 2:
          this.progressValue = 100;
          break;
        default:
          break;
      }
    },

    clearItem() {
      this.step = 1;
      this.formPayload = {
        warehouse_origin_uuid: '',
        warehouse_destination_uuid: '',
        memo: '',
        items: [
          {
            product_uuid: '',
            qty: 0,
          },
        ],
      };
    },

    getWarehouses() {
      this.$store.dispatch('warehouse/getData', {
        params: {
          per_page: 50,
        },
      }).then(result => {
        this.options = [...result.data.data.data];
      }).catch(err => {
        console.log(err);
      });
    },

    createItem() {
      const form = this.preparePayload();

      this.isLoading = true;
      this.$store.dispatch('stockWarehouse/postTransfer', {
        uuid: '',
        payload: form,
      })
        .then(() => {
          this.stock = 1;
          this.$store.commit('formWizard/setStep', 1);
          successNotification(this, 'Success', 'Produk berhasil di transfer');
          this.clearItem();
          this.isLoading = false;
          this.$router.push({ name: 'stock.transfer' });
        })
        .catch(error => {
          if (error.response.data.meta.messages) {
            this.messages = error.response.data.meta.messages;
          }
          this.$bvModal.hide('modal-save');
          this.isLoading = false;
        });
    },

    updateItem() {
      this.formPayload.product_type = 'parent';
      const form = this.preparePayload();
      this.isLoading = true;
      form.append('_method', 'PATCH');

      this.$store.dispatch('parentProduct/postData', {
        query: `/${this.editId}`,
        payload: form,
      })
        .then(() => {
          this.$store.commit('formWizard/setStep', 1);
          successNotification(this, 'Success', 'Produk berhasil di ubah!');
          this.clearItem();
          this.$bvModal.hide('modal-save');
          this.isLoading = false;
          this.$router.push({ name: 'master-product.index' });
          this.$store.commit('formWizard/setStep', 1);
        })
        .catch(error => {
          if (error.response.data.meta.messages) {
            this.messages = error.response.data.meta.messages;
          }
          this.$bvModal.hide('modal-save');
          this.isLoading = false;
        });
    },

    preparePayload() {
      const form = new FormData();

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'items') {
            form.append(key, this.formPayload[key]);
          }
        }
      }

      for (let index = 0; index < this.formPayload.items.length; index++) {
        const element = this.formPayload.items[index];
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            const items = element[key];
            if (key !== 'qty') {
              form.append(`items[${index}][${key}]`, items);
            }
            if (key === 'qty') {
              form.append(`items[${index}][${key}]`, parseFloat(this.preformatFloat(items)));
            }
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

// body {
//   background-color: #fff !important;
// }

.bg-dark {
  background: $dark !important;
}

.btn__add--product {
  background-color: #FFFFFF !important;
  border: 1px solid #E4E5EC !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px;
  font-weight: 800;
}

.custom__input {
  &.error-validation {
    background: #FEEFEF;
    border: 1px solid #F48989;

    .input-group-text {
      background-color: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.error-validation {
  .input-group-text {
    background-color: #FEEFEF !important;
    border: 1px solid #FEEFEF !important;
  }
}

.v-select {
  &.error-validation {
    .vs__dropdown-toggle {
      background: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.progress-bar-success {
  background-color: #EFF1F5;
  .progress-bar {
    background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
    box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
    border-radius: 20px;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}
</style>
